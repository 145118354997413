<template>
    <div>
        <h2 class="mb-1">Finance Information Report</h2>

        <div style="display: flex; flex-wrap: wrap; align-items: center;">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-50"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="prevRange()"
            >
                <feather-icon icon="ChevronLeftIcon" />
            </b-button>

            <b-form-group
                label="Start Date"
                class="mr-50 w-10"
            >
                <date-picker v-model="startDate" type="month" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-if="selectedRangeDate === 'month' || selectedRangeDate === 'quarter'"></date-picker>
                <date-picker v-model="startDate" type="year" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="selectedRangeDate === 'year'"></date-picker>
                <date-picker v-model="startDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="!selectedRangeDate || selectedRangeDate === 'custom'"></date-picker>
            </b-form-group>
            <b-form-group
                label="End Date"
                class="mr-50"
            >
                <date-picker v-model="endDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" :disabled="selectedRangeDate === 'month' || selectedRangeDate === 'quarter' || selectedRangeDate === 'year' ? true : false"></date-picker>
            </b-form-group>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon ml-0"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="nextRange()"
            >
                <feather-icon icon="ChevronRightIcon" />
            </b-button>
            <div :class="!selectedRangeDate ? 'pl-1' : 'pl-1'">
                <b-form-group>
                    <b-form-radio-group
                        v-model="selectedRangeDate"
                        button-variant="outline-primary range-date"
                        buttons
                        class="p-0 mr-2"
                    >
                        <b-form-radio style="height: 37px; padding: 11px;" v-for="(option,index) in optionsRangeDate" :key="index" :value="option.value">{{option.text}}</b-form-radio>
                        <b-form-radio style="height: 37px; padding: 10px;" v-if="selectedRangeDate" value="x">x</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <b-form-group
                label="Clinician Name"
            >
                <v-select
                    v-model="clinicianName"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Clinician Name"
                    label="full"
                    :dense="true"
                    :options="optionsClinicianName"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2 inputReport"
                >
                </v-select>
            </b-form-group>
            <b-form-group
                label="Client Name"
            >
                <v-select
                    v-model="clientName"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Client Name"
                    label="value"
                    :dense="true"
                    :options="optionsClientName"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2 inputReport"
                >
                </v-select>
            </b-form-group>
            <b-form-group
                label="Region"
            >
                <v-select
                    v-model="region"
                    placeholder="Region"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsRegion"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2 inputReport"
                />
            </b-form-group>
            <b-form-group
                label="Borough"
                v-if="region === 'NYC'"
            >
                <v-select
                    v-model="clinicianBorough"
                    placeholder="Borough"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsBorough"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2 inputReport"
                />
            </b-form-group>
            <b-form-group
                label="Program"
            >
                <b-form-select
                    v-model="clinicianProgram"
                    inline
                    :options="optionsProgram"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="min-width: 200px;"
                    class="d-inline-block mr-2 inputReport"
                />
            </b-form-group>
            <b-form-group
                label="Insurance Rates"
                class="ml-2"
            >
                <v-select
                    v-model="insuranceRates"
                    placeholder="Insurance Rates"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsYesNoSurvey"
                    style="background: #fff; min-width: 200px;"
                    class="d-inline-block mr-2 inputReport"
                />
            </b-form-group>
            <b-form-group
                label="Did client provide copay?"
            >
                <v-select
                    v-model="didClientProvideCopay"
                    placeholder="Did client provide copay?"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsYesNoSurvey"
                    style="background: #fff; min-width: 200px;"
                    class="d-inline-block mr-2 inputReport"
                />
            </b-form-group>
            <b-form-group
                label="Did client utilize sliding scale?"
            >
                <v-select
                    v-model="didclientUtilizeSlidingScale"
                    placeholder="Did client utilize sliding scale?"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsYesNoSurvey"
                    style="background: #fff; min-width: 200px;"
                    class="d-inline-block mr-2 inputReport"
                />
            </b-form-group>

            <div>
                <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-primary"
                    class="mb-1 mt-2 p-75"
                    @click="activeFilterFunction"
                >
                    <feather-icon
                        icon="Xicon"
                        class="mr-50"
                    />
                    <span class="align-middle">Reset filters</span>
                </b-button>
            </div>
        </div>

        <b-row class="mt-1">
            <!-- # insurance utilized for session -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterInsuranceUtilizedForSession && counterInsuranceUtilizedForSession !== 'NaN' ? counterInsuranceUtilizedForSession.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span># insurance utilized for session</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Insurance reimbursement amount -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        ${{ counterInsuranceReimbursementAmount && counterInsuranceReimbursementAmount !== 'NaN' ? counterInsuranceReimbursementAmount.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Insurance reimbursement amount</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- # clients that provided co-pay -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterclientsThatProvidedCopay && counterclientsThatProvidedCopay !== 'NaN' ? counterclientsThatProvidedCopay.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span># clients that provided co-pay</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Average of Co-pay -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        ${{ counterAverageofCopay && counterAverageofCopay !== 'NaN' ? counterAverageofCopay.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Average of Co-pay</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Total Sliding Scale -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterTotalSlidingScale && counterTotalSlidingScale !== 'NaN' ? counterTotalSlidingScale.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Sliding Scale</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Amount of sliding scale -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterAmountofSlidingScale && counterAmountofSlidingScale !== 'NaN' ? counterAmountofSlidingScale.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Amount of sliding scale</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <h4 class="mt-2 mb-1">If not utilizing insurance, reason why</h4>
        <b-row>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterClientDoesntHaveInsurance && counterClientDoesntHaveInsurance !== 'NaN' ? counterClientDoesntHaveInsurance.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Client doesn't have insurance</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterDeductibleNotMet && counterDeductibleNotMet !== 'NaN' ? counterDeductibleNotMet.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Deductible not met</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterDontAcceptClientsInsurance && counterDontAcceptClientsInsurance !== 'NaN' ? counterDontAcceptClientsInsurance.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Don't accept client's insurance</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterInsuranceDoesNotCoverTelehealthServices && counterInsuranceDoesNotCoverTelehealthServices !== 'NaN' ? counterInsuranceDoesNotCoverTelehealthServices.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Insurance does not cover telehealth services</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterOther && counterOther !== 'NaN' ? counterOther.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Other</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        
        <div
            class="mb-1 btn btn-default"
            :style="!readyToExport ? 'background: rgba(148, 34, 30, 0.53) none repeat scroll 0% 0%; color: rgb(255, 255, 255); cursor: pointer; opacity: 1;' : 'background: rgb(148, 34, 30) none repeat scroll 0% 0%; color: rgb(255, 255, 255); cursor: pointer; opacity: 1;'"
            @click="checkReadyToExport"
            v-if="isDataSpecialist && json_data.length === 0"
        >
            CSV Export
            <b-spinner small v-if="!readyToExport" />
        </div>
        <download-csv 
            :data="json_data"
            class="mb-1 btn btn-default"
            name="reports-finance-information.csv"
            :style="'background: #94221e; color: #fff; cursor: pointer; opacity: 1;'"
            delimiter=","
            v-if="isDataSpecialist && json_data.length >= 1"
        >
            CSV Export
        </download-csv>
    </div>
</template>

<script>
import { 
    BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BFormSelect, BCard, BAvatar, BCardBody,
    BSpinner, BFormRadioGroup, BFormRadio, BContainer,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            totalRows: 0,
            options: {
                block: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'false',
                    delimiter: ''
                },
            },
            isDataSpecialist: false,
            expandFilter: false,
            readyToExport: true,

            // filters
            startDate: null,
            endDate: null,
            clinicianName: '',
            clientName: '',
            noIndividualSessions: null,
            noGroupSessions: null,
            noRemoteSessions: null,
            noAssessmentSessions: null,
            noCoupleSessions: null,
            noFamilySessions: null,
            isInsuranceUtilizedForClaim: '',
            clientProgressThisMonth: '',
            clientReportsSatisfactionWithTreatmentSessions: '',
            // didClientProvideCopay: '',
            didClientUtilizeSlidingScale: '',
            region: '',
            insuranceRates: '',
            didClientProvideCopay: '',
            didclientUtilizeSlidingScale: '',
            clientUID: '',
            counterInsuranceUtilizedForSessionAtom: '',
            counterInsuranceReimbursementAmountAtom: '',
            counterclientsThatProvidedCopayAtom: '',
            counterAverageofCopayAtom: '',
            counterTotalSlidingScaleAtom: '',
            counterAmountofSlidingScaleAtom: '',
            counterClientDoesntHaveInsuranceAtom: '',
            counterDeductibleNotMetAtom: '',
            counterDontAcceptClientsInsuranceAtom: '',
            counterInsuranceDoesNotCoverTelehealthServicesAtom: '',
            counterOtherAtom: '',

            // old filters
            language: '',
            progress: '',
            insurance: '',

            // data reports
            totalClaims: 0,
            countClientSatisfaction: 0,
            countNA: 0,
            countNoChange: 0,
            countImproved: 0,
            countDeclined: 0,
            countTotalSlidingScale: 0,
            countAverageCopayPerSession: 0,
            arrayClientsIndividualClientsSeen: [],
            arrayFiltradoReportsGeneralIndividualClientsSeen: [],
            counterInsuranceUtilizedForSession: 0,
            counterInsuranceReimbursementAmount: 0,
            counterclientsThatProvidedCopay: 0,
            counterAverageofCopay: 0,
            counterTotalSlidingScale: 0,
            counterAmountofSlidingScale: 0,
            counterClientDoesntHaveInsurance: 0,
            counterDeductibleNotMet: 0,
            counterDontAcceptClientsInsurance: 0,
            counterInsuranceDoesNotCoverTelehealthServices: 0,
            counterOther: 0,

            // vars atomic
            countTotalClientsInsured: 0,
            countTotalClientsInsuredRate: 0,
            countAverageCopayPerSessionAtomic: 0,
            totalInsuranceReimbursementRateForIndividualSession: 0,

            // json export csv
            json_data: [],

            // options
            optionsClinicianName: [],
            optionsClientName: [],
            optionsIsInsuranceUtilizedForClaim: ['Yes', 'No'],
            optionsClientProgressThisMonth: ['N/A','Declined','Improved','No Change'],
            optionsClientReportsSatisfactionWithTreatmentSessions: ['Yes', 'No'],
            optionsDidClientProvideCopay: ['Yes', 'No'],
            optionsDidClientUtilizeSlidingScale: ['Yes', 'No'],
            optionsRegion: [],
            clinicianBorough: null,
            optionsBorough: [
                'Any Borough',
                'Bronx',
                'Brooklyn',
                'Manhattan',
                'Queens',
                'Staten Island',
            ],
            optionsLanguage: [],
            optionsClientProgress: [
                'Any Client Status',
                'Engaged',
                'Closed - Attended First Session',
                'Closed - Did not attend first session',
                'Closed - Received information',
                'Closed - Referred to OASAS Program',
                'External Referral',
                'On Hold'
            ],
            optionsInsurance: [],

            // settings table and edit view
            statusEdit: false,
            itemsActivity: [],
            fieldsTable: [
                { key: 'dateFormat', sortable: false },
                { key: 'countClaims', sortable: false },
            ],

            // get data from db
            optionsClient: [],
            optionsClinician: [],

            // filter
            clientActivityName: null,
            clientActivityStatus: null,
            selectedRangeDate: 'month',
            optionsRangeDate: [
                { text: 'Month', value: 'month' },
                { text: 'Quarter', value: 'quarter' },
                { text: 'Year', value: 'year' },
                { text: 'Custom', value: 'custom' },
            ],
            clinicianProgram: null,
            optionsProgram: [],
            optionsConnected: ['Yes', 'No'],
            // survey
            isClientInsured: '',
            isClientMatchedToaClinicianWhoAcceptsTheirInsurance: '',
            isTheClinicianBillingTheClientsInsurance: '',
            whyInsuranceWasNotBilled: '',
            reasonWhyClientWasReferredToClinician: '',
            optionsYesNoSurvey: ['Yes', 'No'],
            optionsWhyInsuranceWasNotBilled: [
                'Cost of deductible',
                'Doesn’t want employer to find out',
                'Doesn’t want family member to find out',
                'Other'
            ],
            optionsReasonWhyClientWasReferredToClinician: [
                'Language',
                'Distance',
                'Religion',
                'Culture',
                'Gender',
                'Remote care services',
                'Fear/Stigma',
                'Availability',
                'Client requested specific clinician',
                'Other'
            ],

            // reports cards
            totalGroupSessions: 0,
            totalIndividualSessions: 0,
            totalRemoteSessions: 0,
            totalAssessmentSessions: 0,
            totalFamilySessions: 0,
            totalCoupleSessions: 0,
            totalNonEnglishClients: 0,
            clientSatisfaction: 0,
            improved: 0,
            totalNonEnglishClientsStatic: 0,
            totalCopayWithoutFilters: 0,

            // isBusy: true,
            latestDoc: null,
            latestDocQuery: null,
            itemsClaimsQuery: [],
            apiLoaded: false,
            loadingInfiniteScroll: false,
            activeFiltersDate: false,

            // overlay data
            rangeDinamic: false,
            isBusy: true,
            isBusyClientsSeen: true,
            loadTotalClients: true,
            activeFilters: false,
            firstLoadDOM: true,
            firstLoadData: true,
            seenClients: [],
            counterCases: 0,
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BFormSelect,
        BCard,
        BAvatar,
        BCardBody,
        BSpinner,
        BFormRadioGroup,
        BFormRadio,
        BContainer,
        Cleave,
        
        // select
        vSelect,

        // date
        flatPickr,
        DatePicker,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters(['arrayFiltradoReportsFinanceInformation'])
    },
    watch: {
        selectedRangeDate(val) {
            this.functionDateRange(val)
        },
        startDate(val) {
            // console.log(val)
            this.isBusy = true
            this.searchReportsFinanceInformationStartDate(val)
            this.checkActiveFilters()
            this.latestDocQuery = null
            this.functionDateRange(this.selectedRangeDate)
            if(!val) {
                this.filterRestard()
            }
            this.rangeDinamic ? this.rangeDinamic = false : ''
        },
        endDate(val) {
            // console.log(val)
            this.isBusy = true
            this.checkActiveFilters()
            this.latestDocQuery = null
            this.searchReportsFinanceInformationEndDate(val)
            if(!val) {
                this.filterRestard()
            }
        },
        clinicianName(val) {
            // console.log(val)
            this.checkActiveFilters()
            this.latestDocQuery = null
            if(val) {
                this.isBusy = true
                if(val.value === 'All Clinicians') {
                    this.clinicianName = null
                } else {
                    this.searchReportsFinanceInformationClinicianName(val.uid)
                }
            } else {
                this.isBusy = true
                this.filterRestard()
                this.searchReportsFinanceInformationClinicianName('')
            }
        },
        clientName(val) {
            // console.log(val)
            // this.checkActiveFilters()
            this.latestDocQuery = null
            this.clientUID = ''
            if(val) {
                // console.log(val)
                this.isBusy = true
                if(val.value === 'All Clients') {
                    this.clientName = null
                    this.checkActiveFilters()
                } else {
                    this.clientUID = val.uid ? val.uid : ''
                    this.checkActiveFilters()
                    this.searchReportsFinanceInformationClientName(val.uid)
                }
            } else {
                this.isBusy = true
                this.filterRestard()
                this.searchReportsFinanceInformationClientName('')
                this.checkActiveFilters()
            }
        },
        region(val) {
            // console.log(val)
            this.checkActiveFilters()
            this.latestDocQuery = null
            this.isBusy = true
            if(!val) {
                this.filterRestard()
            }
            
            this.searchReportsFinanceInformationRegion(val)
        },
        clinicianBorough(val) {
            // console.log(val)
            this.checkActiveFilters()
            this.latestDocQuery = null
            this.isBusy = true
            if(!val) {
                this.filterRestard()
            }
            
            this.searchReportsFinanceInformationRegion(val === 'Any Borough' ? null : val)
        },
        clinicianProgram(val) {
            // console.log(val)
            this.checkActiveFilters()
            this.latestDocQuery = null
            this.isBusy = true
            this.searchReportsFinanceInformationProgram(val)
        },
        insuranceRates(val) {
            this.checkActiveFilters()
            this.latestDocQuery = null
            this.isBusy = true
            this.searchReportsFinanceInformationInsuranceRates(val)
        },
        didClientProvideCopay(val) {
            this.checkActiveFilters()
            this.latestDocQuery = null
            this.isBusy = true
            this.searchReportsFinanceInformationDidClientProvideCopay(val)
        },
        didclientUtilizeSlidingScale(val) {
            this.checkActiveFilters()
            this.latestDocQuery = null
            this.isBusy = true
            this.searchReportsFinanceInformationDidclientUtilizeSlidingScale(val)
        },
        arrayFiltradoReportsFinanceInformation(val) {
            if(this.counterCases > 0) {
                this.counterInsuranceUtilizedForSession = 0
                this.counterInsuranceReimbursementAmount = 0
                this.counterclientsThatProvidedCopay = 0
                this.counterAverageofCopay = 0
                this.counterTotalSlidingScale = 0
                this.counterAmountofSlidingScale = 0
                this.counterClientDoesntHaveInsurance = 0
                this.counterDeductibleNotMet = 0
                this.counterDontAcceptClientsInsurance = 0
                this.counterInsuranceDoesNotCoverTelehealthServices = 0
                this.counterOther = 0
                var arrayExport = []
                var averageReimbursement = 0
                var counterClaimsWCopay = 0
                var averageCopay = 0
                this.readyToExport = false
                this.isBusy = true
                
                val.forEach(dataClaim => {
                    // # insurance utilized for session
                    if(dataClaim.insuranceSession) {
                        this.counterInsuranceUtilizedForSession += 1
                    }

                    // Insurance reimbursement amount
                    // if(dataClaim.insuranceReimbursementRateForIndividualSessions) {
                    //     this.counterInsuranceReimbursementAmount += parseFloat(dataClaim.insuranceReimbursementRateForIndividualSessions)
                    // }
                    // if(dataClaim.insuranceReimbursementRateForGroupSessions) {
                    //     this.counterInsuranceReimbursementAmount += parseFloat(dataClaim.insuranceReimbursementRateForGroupSessions)
                    // }
                    // if(dataClaim.insuranceReimbursementRateForRemoteSessions) {
                    //     this.counterInsuranceReimbursementAmount += parseFloat(dataClaim.insuranceReimbursementRateForRemoteSessions)
                    // }

                    // # clients that provided co-pay
                    if(dataClaim.didClientProvideCopay) {
                        this.counterclientsThatProvidedCopay += 1
                    }

                    // Average reim
                    var amountReimbursement = 0
                    if(dataClaim.aicp.length) {
                        dataClaim.aicp.forEach(ele => {
                            amountReimbursement += parseInt(ele.enterAmmountInsuranceCompanyPay, 10)
                        })
                        averageReimbursement += amountReimbursement
                    }

                    // Average of Co-pay
                    var cpyAmout = 0
                    if(dataClaim.copayAmount.length) {
                        dataClaim.copayAmount.forEach(ele => {
                            counterClaimsWCopay += 1
                            cpyAmout += parseInt(ele.copayAmount, 10)
                        })
                        averageCopay += cpyAmout
                        // this.counterAverageofCopay = cpyAmout
                    }

                    // Total Sliding Scale
                    if(dataClaim.slidingScale) {
                        this.counterTotalSlidingScale += 1
                    }

                    // Amount of sliding scale
                    var sldAmout = 0
                    if(dataClaim.amountSliding.length) {
                        dataClaim.amountSliding.forEach(ele => {
                            sldAmout += parseFloat(ele.amount)
                        })
                        this.counterAmountofSlidingScale += sldAmout
                    }

                    // if not utilizing insurance, reason why
                    var concatifNotUtilizingInsuranceSession = ''
                    if(dataClaim.inuis.length >= 1) {
                        dataClaim.inuis.forEach(ele => {
                            if(ele.ifNotUtilizingInsuranceSession.includes("Client doesn't have insurance")) {
                                concatifNotUtilizingInsuranceSession ? concatifNotUtilizingInsuranceSession += ', ' + ele.ifNotUtilizingInsuranceSession : ele.ifNotUtilizingInsuranceSession
                                this.counterClientDoesntHaveInsurance += 1
                            } else if(ele.ifNotUtilizingInsuranceSession.includes("Deductible not met")) {
                                concatifNotUtilizingInsuranceSession ? concatifNotUtilizingInsuranceSession += ', ' + ele.ifNotUtilizingInsuranceSession : ele.ifNotUtilizingInsuranceSession
                                this.counterDeductibleNotMet += 1
                            } else if(ele.ifNotUtilizingInsuranceSession.includes("Don't accept client's insurance")) {
                                concatifNotUtilizingInsuranceSession ? concatifNotUtilizingInsuranceSession += ', ' + ele.ifNotUtilizingInsuranceSession : ele.ifNotUtilizingInsuranceSession
                                this.counterDontAcceptClientsInsurance += 1
                            } else if(ele.ifNotUtilizingInsuranceSession.includes("Insurance does not cover telehealth services")) {
                                concatifNotUtilizingInsuranceSession ? concatifNotUtilizingInsuranceSession += ', ' + ele.ifNotUtilizingInsuranceSession : ele.ifNotUtilizingInsuranceSession
                                this.counterInsuranceDoesNotCoverTelehealthServices += 1
                            } else if(ele.ifNotUtilizingInsuranceSession.includes("Other")) {
                                concatifNotUtilizingInsuranceSession ? concatifNotUtilizingInsuranceSession += ', ' + ele.ifNotUtilizingInsuranceSession : ele.ifNotUtilizingInsuranceSession
                                this.counterOther += 1
                            }
                        })
                    }

                    arrayExport.push({
                        totalGroupSessions: dataClaim.tgs,
                        totalIndividualSessions: dataClaim.tis,
                        totalRemoteSessions: dataClaim.trs,
                        totalAssessmentSessions: dataClaim.tas,
                        totalFamilySessions: dataClaim.tfs,
                        totalCoupleSessions: dataClaim.tcs,
                        // client: dataClaim.,
                        // clinician: dataClaim.,

                        // client information
                        clientID: dataClaim.clientID ? dataClaim.clientID : '',
                        insurance: dataClaim.insurance ? dataClaim.insurance : 'None entered',
                        county: dataClaim.region ? dataClaim.region : '',
                        language: dataClaim.language ? dataClaim.language : '',
                        // gender: dataClaim.gender ? dataClaim.gender : '',
                        program: dataClaim.clinicianProgram ? dataClaim.clinicianProgram : '',
                        didClientUtilizeSlidingScale: dataClaim.didClientUtilizeSlidingScale,
                        clientProgressThisMonth: dataClaim.clientProgressThisMonth,
                        clientReportsSatisfactionWithTreatmentSessions: dataClaim.cRSWTS,
                        // didClientProvideCopay: dataClaim.didClientProvideCopay ? dataClaim.didClientProvideCopay : '',
                        slidingScale: dataClaim.slidingScale,

                        approvalStatus: dataClaim.approvalStatus,
                        insuranceSession: dataClaim.insuranceSession,
                        amountBilledToCFE: dataClaim.amountBilledToCFE,
                        noIndividualSessions: dataClaim.tis,
                        noGroupSessions: dataClaim.tgs,
                        noRemoteSessions: dataClaim.trs,
                        noAssessmentSessions: dataClaim.tas,
                        noCoupleSessions: dataClaim.tcs,
                        noFamilySessions: dataClaim.tfs,
                        isInsuranceUtilizedForClaim: dataClaim.isInsuranceUtilizedForClaim,
                        regionClinician: dataClaim.regionClinician,
                        // didClientProvideCopay: dataClaim.,
                        // didClientUtilizeSlidingScale: dataClaim.,
                        // insuranceReimbursementRateForIndividualSessions: dataClaim.insuranceReimbursementRateForIndividualSessions,
                        claimDate: dataClaim.claimDate ? moment(dataClaim.claimDate,'DD/MM/YYYY').format('MM/DD/YYYY') : '',
                        // claimMonthYear: dataClaim.,
                        // createdFormat: dataClaim.,
                        // created: dataClaim.,
                        // actualDate: dataClaim.,
                        // itemsFormRepeater: dataClaim.,

                        // data required
                        insuranceUtilizedforSession: dataClaim.insuranceSession ? 'Yes' : 'No',
                        insuranceReimbursementRateForIndividualSessions: dataClaim.iRIS ? dataClaim.iRIS : 0,
                        insuranceReimbursementRateForGroupSessions: dataClaim.iRRGS ? dataClaim.iRRGS : 0,
                        insuranceReimbursementRateForRemoteSessions: dataClaim.iRRRS ? dataClaim.iRRRS : 0,
                        reimbursementAmount: amountReimbursement,
                        didClientProvideCopay: dataClaim.didClientProvideCopay ? 'Yes' : 'No',
                        copayAmount: cpyAmout,
                        slidingScale: dataClaim.slidingScale ? 'Yes' : 'No',
                        amountSliding: sldAmout,
                        ifNotUtilizingInsuranceSession: concatifNotUtilizingInsuranceSession
                    })
                })
                this.counterAverageofCopay = parseInt((averageCopay / counterClaimsWCopay), 10)
                this.counterInsuranceReimbursementAmount = parseInt(averageReimbursement, 10)

                if(!this.activeFilters && this.firstLoadDOM) {
                    this.firstLoadDOM = false
                    db.collection('reportFinance').doc('counters').update({
                        counterInsuranceUtilizedForSession: this.counterInsuranceUtilizedForSession,
                        counterInsuranceReimbursementAmount: this.counterInsuranceReimbursementAmount,
                        counterclientsThatProvidedCopay: this.counterclientsThatProvidedCopay,
                        counterAverageofCopay: this.counterAverageofCopay,
                        counterTotalSlidingScale: this.counterTotalSlidingScale,
                        counterAmountofSlidingScale: this.counterAmountofSlidingScale,
                        counterClientDoesntHaveInsurance: this.counterClientDoesntHaveInsurance,
                        counterDeductibleNotMet: this.counterDeductibleNotMet,
                        counterDontAcceptClientsInsurance: this.counterDontAcceptClientsInsurance,
                        counterInsuranceDoesNotCoverTelehealthServices: this.counterInsuranceDoesNotCoverTelehealthServices,
                        counterOther: this.counterOther
                    })
                }
                // console.log(counterClaimsWCopay)
                
                this.json_data = arrayExport
                this.readyToExport = true
                // console.log('first')
                setTimeout(() => {
                    this.isBusy = false
                }, 1700)
            }
        }
    },
    methods: {
        ...mapActions(['setSnapshotClientActivityReportsFinanceInformation','searchReportsFinanceInformationStartDate','searchReportsFinanceInformationEndDate','searchReportsFinanceInformationClinicianName','searchReportsFinanceInformationClientName','searchReportsFinanceInformationRegion','searchReportsFinanceInformationProgram','searchReportsFinanceInformationInsuranceRates','searchReportsFinanceInformationDidClientProvideCopay','searchReportsFinanceInformationDidclientUtilizeSlidingScale']),
        activeFilterFunction() {
            this.startDate = null
            this.endDate = null
            this.clinicianName = ''
            this.clientName = ''
            this.noIndividualSessions = null
            this.noGroupSessions = null
            this.noRemoteSessions = null
            this.noAssessmentSessions = null
            this.noCoupleSessions = null
            this.noFamilySessions = null
            this.isInsuranceUtilizedForClaim = ''
            this.clientProgressThisMonth = ''
            this.clientReportsSatisfactionWithTreatmentSessions = ''
            // this.didClientProvideCopay = ''
            // this.didClientUtilizeSlidingScale = ''
            this.region = ''
            this.insuranceRates = ''
            this.didClientProvideCopay = ''
            this.didclientUtilizeSlidingScale = ''
            this.clinicianBorough = null
            this.clinicianProgram = null
            this.activeFilters = false
        },
        arrayFiltradoReportsFinanceInformationMethod(val) {
            // if(this.counterCases > 0) {
                this.counterInsuranceUtilizedForSession = 0
                this.counterInsuranceReimbursementAmount = 0
                this.counterclientsThatProvidedCopay = 0
                this.counterAverageofCopay = 0
                this.counterTotalSlidingScale = 0
                this.counterAmountofSlidingScale = 0
                this.counterClientDoesntHaveInsurance = 0
                this.counterDeductibleNotMet = 0
                this.counterDontAcceptClientsInsurance = 0
                this.counterInsuranceDoesNotCoverTelehealthServices = 0
                this.counterOther = 0
                var arrayExport = []
                var averageReimbursement = 0
                var counterClaimsWCopay = 0
                var averageCopay = 0
                this.readyToExport = false
                this.isBusy = true

                // console.log(val.length)
                
                val.forEach(dataClaim => {
                    // # insurance utilized for session
                    if(dataClaim.insuranceSession) {
                        this.counterInsuranceUtilizedForSession += 1
                    }

                    // Insurance reimbursement amount
                    // if(dataClaim.insuranceReimbursementRateForIndividualSessions) {
                    //     this.counterInsuranceReimbursementAmount += parseFloat(dataClaim.insuranceReimbursementRateForIndividualSessions)
                    // }
                    // if(dataClaim.insuranceReimbursementRateForGroupSessions) {
                    //     this.counterInsuranceReimbursementAmount += parseFloat(dataClaim.insuranceReimbursementRateForGroupSessions)
                    // }
                    // if(dataClaim.insuranceReimbursementRateForRemoteSessions) {
                    //     this.counterInsuranceReimbursementAmount += parseFloat(dataClaim.insuranceReimbursementRateForRemoteSessions)
                    // }

                    // # clients that provided co-pay
                    if(dataClaim.didClientProvideCopay) {
                        this.counterclientsThatProvidedCopay += 1
                    }

                    // Average reim
                    var amountReimbursement = 0
                    if(dataClaim.aicp.length) {
                        dataClaim.aicp.forEach(ele => {
                            amountReimbursement += parseInt(ele.enterAmmountInsuranceCompanyPay, 10)
                        })
                        averageReimbursement += amountReimbursement
                    }

                    // Average of Co-pay
                    var cpyAmout = 0
                    if(dataClaim.copayAmount.length) {
                        dataClaim.copayAmount.forEach(ele => {
                            counterClaimsWCopay += 1
                            cpyAmout += parseInt(ele.copayAmount, 10)
                        })
                        averageCopay += cpyAmout
                        // this.counterAverageofCopay = cpyAmout
                    }

                    // Total Sliding Scale
                    if(dataClaim.slidingScale) {
                        this.counterTotalSlidingScale += 1
                    }

                    // Amount of sliding scale
                    var sldAmout = 0
                    if(dataClaim.amountSliding.length) {
                        dataClaim.amountSliding.forEach(ele => {
                            sldAmout += parseFloat(ele.amount)
                        })
                        this.counterAmountofSlidingScale += sldAmout
                    }

                    // if not utilizing insurance, reason why
                    var concatifNotUtilizingInsuranceSession = ''
                    if(dataClaim.inuis.length >= 1) {
                        dataClaim.inuis.forEach(ele => {
                            if(ele.ifNotUtilizingInsuranceSession.includes("Client doesn't have insurance")) {
                                concatifNotUtilizingInsuranceSession ? concatifNotUtilizingInsuranceSession += ', ' + ele.ifNotUtilizingInsuranceSession : ele.ifNotUtilizingInsuranceSession
                                this.counterClientDoesntHaveInsurance += 1
                            } else if(ele.ifNotUtilizingInsuranceSession.includes("Deductible not met")) {
                                concatifNotUtilizingInsuranceSession ? concatifNotUtilizingInsuranceSession += ', ' + ele.ifNotUtilizingInsuranceSession : ele.ifNotUtilizingInsuranceSession
                                this.counterDeductibleNotMet += 1
                            } else if(ele.ifNotUtilizingInsuranceSession.includes("Don't accept client's insurance")) {
                                concatifNotUtilizingInsuranceSession ? concatifNotUtilizingInsuranceSession += ', ' + ele.ifNotUtilizingInsuranceSession : ele.ifNotUtilizingInsuranceSession
                                this.counterDontAcceptClientsInsurance += 1
                            } else if(ele.ifNotUtilizingInsuranceSession.includes("Insurance does not cover telehealth services")) {
                                concatifNotUtilizingInsuranceSession ? concatifNotUtilizingInsuranceSession += ', ' + ele.ifNotUtilizingInsuranceSession : ele.ifNotUtilizingInsuranceSession
                                this.counterInsuranceDoesNotCoverTelehealthServices += 1
                            } else if(ele.ifNotUtilizingInsuranceSession.includes("Other")) {
                                concatifNotUtilizingInsuranceSession ? concatifNotUtilizingInsuranceSession += ', ' + ele.ifNotUtilizingInsuranceSession : ele.ifNotUtilizingInsuranceSession
                                this.counterOther += 1
                            }
                        })
                    }

                    arrayExport.push({
                        totalGroupSessions: dataClaim.tgs,
                        totalIndividualSessions: dataClaim.tis,
                        totalRemoteSessions: dataClaim.trs,
                        totalAssessmentSessions: dataClaim.tas,
                        totalFamilySessions: dataClaim.tfs,
                        totalCoupleSessions: dataClaim.tcs,
                        // client: dataClaim.,
                        // clinician: dataClaim.,

                        // client information
                        clientID: dataClaim.clientID ? dataClaim.clientID : '',
                        insurance: dataClaim.insurance ? dataClaim.insurance : 'None entered',
                        county: dataClaim.region ? dataClaim.region : '',
                        language: dataClaim.language ? dataClaim.language : '',
                        // gender: dataClaim.gender ? dataClaim.gender : '',
                        program: dataClaim.clinicianProgram ? dataClaim.clinicianProgram : '',
                        didClientUtilizeSlidingScale: dataClaim.didClientUtilizeSlidingScale,
                        clientProgressThisMonth: dataClaim.clientProgressThisMonth,
                        clientReportsSatisfactionWithTreatmentSessions: dataClaim.cRSWTS,
                        // didClientProvideCopay: dataClaim.didClientProvideCopay ? dataClaim.didClientProvideCopay : '',
                        slidingScale: dataClaim.slidingScale,

                        approvalStatus: dataClaim.approvalStatus,
                        insuranceSession: dataClaim.insuranceSession,
                        amountBilledToCFE: dataClaim.amountBilledToCFE,
                        noIndividualSessions: dataClaim.tis,
                        noGroupSessions: dataClaim.tgs,
                        noRemoteSessions: dataClaim.trs,
                        noAssessmentSessions: dataClaim.tas,
                        noCoupleSessions: dataClaim.tcs,
                        noFamilySessions: dataClaim.tfs,
                        isInsuranceUtilizedForClaim: dataClaim.isInsuranceUtilizedForClaim,
                        regionClinician: dataClaim.regionClinician,
                        // didClientProvideCopay: dataClaim.,
                        // didClientUtilizeSlidingScale: dataClaim.,
                        // insuranceReimbursementRateForIndividualSessions: dataClaim.insuranceReimbursementRateForIndividualSessions,
                        claimDate: dataClaim.claimDate ? moment(dataClaim.claimDate,'DD/MM/YYYY').format('MM/DD/YYYY') : '',
                        // claimMonthYear: dataClaim.,
                        // createdFormat: dataClaim.,
                        // created: dataClaim.,
                        // actualDate: dataClaim.,
                        // itemsFormRepeater: dataClaim.,

                        // data required
                        insuranceUtilizedforSession: dataClaim.insuranceSession ? 'Yes' : 'No',
                        insuranceReimbursementRateForIndividualSessions: dataClaim.iRIS ? dataClaim.iRIS : 0,
                        insuranceReimbursementRateForGroupSessions: dataClaim.iRRGS ? dataClaim.iRRGS : 0,
                        insuranceReimbursementRateForRemoteSessions: dataClaim.iRRRS ? dataClaim.iRRRS : 0,
                        reimbursementAmount: amountReimbursement,
                        didClientProvideCopay: dataClaim.didClientProvideCopay ? 'Yes' : 'No',
                        copayAmount: cpyAmout,
                        slidingScale: dataClaim.slidingScale ? 'Yes' : 'No',
                        amountSliding: sldAmout,
                        ifNotUtilizingInsuranceSession: concatifNotUtilizingInsuranceSession
                    })
                })
                this.counterAverageofCopay = parseInt((averageCopay / counterClaimsWCopay), 10)
                this.counterInsuranceReimbursementAmount = parseInt(averageReimbursement, 10)

                // if(!this.activeFilters && this.firstLoadDOM) {
                //     this.firstLoadDOM = false
                //     db.collection('reportFinance').doc('counters').update({
                //         counterInsuranceUtilizedForSession: this.counterInsuranceUtilizedForSession,
                //         counterInsuranceReimbursementAmount: this.counterInsuranceReimbursementAmount,
                //         counterclientsThatProvidedCopay: this.counterclientsThatProvidedCopay,
                //         counterAverageofCopay: this.counterAverageofCopay,
                //         counterTotalSlidingScale: this.counterTotalSlidingScale,
                //         counterAmountofSlidingScale: this.counterAmountofSlidingScale,
                //         counterClientDoesntHaveInsurance: this.counterClientDoesntHaveInsurance,
                //         counterDeductibleNotMet: this.counterDeductibleNotMet,
                //         counterDontAcceptClientsInsurance: this.counterDontAcceptClientsInsurance,
                //         counterInsuranceDoesNotCoverTelehealthServices: this.counterInsuranceDoesNotCoverTelehealthServices,
                //         counterOther: this.counterOther
                //     })
                // }
                // console.log(counterClaimsWCopay)
                
                this.json_data = arrayExport
                // this.readyToExport = true
                // // console.log('first')
                // setTimeout(() => {
                //     this.isBusy = false
                // }, 1700)
            // }
        },
        async queryClaims() {
            // this.startDate && this.endDate ? this.activeFiltersDate = true : this.activeFiltersDate = false
            // this.clearSelected()
            this.activeFiltersDate = true
            !this.latestDocQuery ? this.itemsClaimsQuery = [] : ''
            this.clinicianBorough = this.clinicianBorough === 'bronx' ? 'Bronx' : this.clinicianBorough === 'brooklyn' ? 'Brooklyn' : this.clinicianBorough === 'manhattan' ? 'Manhattan' : this.clinicianBorough === 'queens' ? 'Queens' : this.clinicianBorough === 'staten island' ? 'Staten Island' : this.clinicianBorough
            // console.log(this.clientID)
            // console.log(this.clinicianName)
            // console.log(this.clientName)

            if(this.startDate && this.endDate) {
                var dateFrom = this.startDate.split("/")
                var dateTo = this.endDate.split("/")
                // var dateCheck = claimDateFormat.split("/")
                var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
                from.setUTCHours(0)
                from.setUTCMinutes(0)
                from.setUTCSeconds(0)
                from.setUTCMilliseconds(0)

                var to = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
                to.setUTCHours(0)
                to.setUTCMinutes(0)
                to.setUTCSeconds(0)
                to.setUTCMilliseconds(0)
                // .where('statusClinician', '==', this.clinicianStatus)
                // .where('regionBorough', '==', this.clinicianBorough)
                // borough: this.clinicianBorough,
                // if(this.clinicianBorough) {

                if(!this.clinicianName && !this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()

                    // ouputs docs
                    // .then(docsClients => {
                    var arrayClientsClaims = []
                    data.docs.forEach(itemClaim => {
                        var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                        // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                        if(filterClaim.length === 0) {
                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                            if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                            iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                        isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                        clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                    cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                            clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                            language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                            // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                        clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                    }

                    arrayClientsClaims.push(obj)
                }
        }
                    })
                    this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                    // console.log(this.itemsClaimsQuery.length)
                    if(!data.empty) {
                        this.latestDocQuery = data.docs[data.docs.length - 1]
                    }
                    this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                    this.apiLoaded = false
                    this.loadingInfiniteScroll = false
                    this.isBusy = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clientName && !this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(this.clinicianBorough) {
                        
                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('regionBorough', '==', this.clinicianBorough).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    } else {

                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('region', '==', this.clinicianRegion).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(this.clinicianName && !this.clientName && !this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(this.clinicianBorough) {

                        // var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('regionBorough', '==', this.clinicianBorough).where('clinicianID', '==', this.clinicianName.codigo).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    } else {

                        // var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('region', '==', this.clinicianRegion).where('clinicianID', '==', this.clinicianName.codigo).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(!this.clinicianName && this.clientName && !this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(this.clinicianBorough) {

                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('regionBorough', '==', this.clinicianBorough).where('client', '==', this.clientUID).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    } else {

                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('region', '==', this.clinicianRegion).where('client', '==', this.clientUID).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(!this.clinicianName && !this.clientName && this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(this.clinicianBorough) {

                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('regionBorough', '==', this.clinicianBorough).where('approvalStatus', '==', this.claimStatus).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    } else {

                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('region', '==', this.clinicianRegion).where('approvalStatus', '==', this.claimStatus).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(!this.clinicianName && !this.clientName && !this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientID && this.clinicianProgram) {
                    if(this.clinicianBorough) {

                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('regionBorough', '==', this.clinicianBorough).where('clinicianProgram', '==', this.clinicianProgram).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    } else {

                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('region', '==', this.clinicianRegion).where('clinicianProgram', '==', this.clinicianProgram).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(this.clinicianName && !this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('clinicianID', '==', this.clinicianName.codigo).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    

                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('clientName', '==', this.clientName).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clientName && !this.claimStatus && !this.clinicianRegion && this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('statusClinician', '==', this.clinicianStatus).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clientName && this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    

                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('approvalStatus', '==', this.claimStatus).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('clinicianProgram', '==', this.clinicianProgram).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            } else if(this.clinicianName && this.clinicianName.codigo) {
                // QUERY CLINICIAN NAME
                if(!this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('clinicianID', '==', this.clinicianName.codigo).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('clinicianID', '==', this.clinicianName.codigo).where('client', '==', this.clientUID).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clientName && this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('clinicianID', '==', this.clinicianName.codigo).where('approvalStatus', '==', this.claimStatus).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clientName && !this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(this.clinicianBorough) {
                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('clinicianID', '==', this.clinicianName.codigo).where('regionBorough', '==', this.clinicianBorough).orderBy('status').orderBy('claimDate', 'desc')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                this.totalRowsQuery = this.itemsClaimsQuery.length
                                this.loadCounterQuery = false
                            // }
                        // })
                    } else {
                        
                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('clinicianID', '==', this.clinicianName.codigo).where('region', '==', this.clinicianRegion).orderBy('status').orderBy('claimDate', 'desc')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(!this.clientName && !this.claimStatus && !this.clinicianRegion && this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('clinicianID', '==', this.clinicianName.codigo).where('statusClinician', '==', this.clinicianStatus).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
            }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('clinicianID', '==', this.clinicianName.codigo).where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            } else if(this.clientName && this.clientName.value) {
                // QUERY CLIENT NAME
                if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('client', '==', this.clientUID).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                }
                            })
                            // console.log(arrayClientsClaims.length,this.clientUID)
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('client', '==', this.clientUID).where('clientName', '==', this.clinicianName.codigo).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('client', '==', this.clientUID).where('approvalStatus', '==', this.claimStatus).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(this.clinicianBorough) {
                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('client', '==', this.clientUID).where('regionBorough', '==', this.clinicianBorough).orderBy('status').orderBy('claimDate', 'desc')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                this.totalRowsQuery = this.itemsClaimsQuery.length
                                this.loadCounterQuery = false
                            // }
                        // })
                    } else {
                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('client', '==', this.clientUID).where('region', '==', this.clinicianRegion).orderBy('status').orderBy('claimDate', 'desc')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                this.totalRowsQuery = this.itemsClaimsQuery.length
                                this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('client', '==', this.clientUID).where('statusClinician', '==', this.clinicianStatus).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                        var didClientUtilizeSlidingScale = false
                                        var isInsuranceUtilizedForSession = false
                                        var didClientProvideCopay = false
                                        var ammountInsuranceCompanyPay = []
                                        var ifNotUtilizingInsuranceSession = []
                                        var copayAmount = []
                                        var amountSliding = []
                                        if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                            var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                            var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                            var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                            var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                            var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                            var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                            var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                            if(resultInsurance.length >= 1) {
                                                isInsuranceUtilizedForSession = true
                                            }

                                            if(resultSliding.length >= 1) {
                                                didClientUtilizeSlidingScale = true
                                            }

                                            if(resultdidClientProvideCopay.length >= 1) {
                                                didClientProvideCopay = true
                                            }

                                            if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                            }

                                            if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                            }

                                            if(resultcopayAmount.length >= 1) {
                                                copayAmount = resultcopayAmount
                                            }

                                            if(resultamountSliding.length >= 1) {
                                                amountSliding = resultamountSliding
                                            }
                                        }

                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        var obj = {
                                            id: itemClaim.id,
                                            tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                            tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                            trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                            tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                            tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                            tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                            client: itemClaim.data().client ? itemClaim.data().client : '',
                                            clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                            region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                            regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                            approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                            slidingScale: didClientUtilizeSlidingScale,
                                            insuranceSession: isInsuranceUtilizedForSession,
                                            didClientProvideCopay: didClientProvideCopay,
                                            inuis: ifNotUtilizingInsuranceSession,
                                            aicp: ammountInsuranceCompanyPay,
                                            amountBilledToCFE: amountBilledToCFE,
                                            copayAmount: copayAmount,
                                            amountSliding: amountSliding,

                                            iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                            iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                            iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                            isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                            clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                            cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                            claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                            insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                            clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                            language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                            // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                            clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                        }

                                        arrayClientsClaims.push(obj)
                                    }
                                }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)

                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('client', '==', this.clientUID).where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
            }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            } else if(this.clinicianStatus) {
                // QUERY STATUS CLINICIAN
                if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('statusClinician', '==', this.clinicianStatus).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
            }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('statusClinician', '==', this.clinicianStatus).where('clinicianID', '==', this.clinicianName.codigo).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clientID && this.clientName && !this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('statusClinician', '==', this.clinicianStatus).where('client', '==', this.clientUID).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    if(this.clinicianBorough) {
                        
                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('statusClinician', '==', this.clinicianStatus).where('regionBorough', '==', this.clinicianBorough).orderBy('status').orderBy('claimDate', 'desc')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    } else {
                        
                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('statusClinician', '==', this.clinicianStatus).where('region', '==', this.clinicianRegion).orderBy('status').orderBy('claimDate', 'desc')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(!this.clinicianName && this.claimStatus && !this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('statusClinician', '==', this.clinicianStatus).where('approvalStatus', '==', this.claimStatus).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clientID && !this.clientName && this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('statusClinician', '==', this.clinicianStatus).where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            } else if(this.claimStatus) {
                // QUERY STATUS CLINICIAN
                if(!this.clinicianName && !this.clinicianStatus && !this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('approvalStatus', '==', this.claimStatus).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                        // }
                    // })
                } else if(this.clinicianName && !this.clinicianStatus && !this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('approvalStatus', '==', this.claimStatus).where('clinicianID', '==', this.clinicianName.codigo).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && !this.clinicianRegion && !this.clientID && this.clientName && !this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('approvalStatus', '==', this.claimStatus).where('client', '==', this.clientUID).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    if(this.clinicianBorough) {
                        
                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('approvalStatus', '==', this.claimStatus).where('regionBorough', '==', this.clinicianBorough).orderBy('status')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    } else {
                        
                        var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '==', 'active').where('approvalStatus', '==', this.claimStatus).where('region', '==', this.clinicianRegion).orderBy('status')
                        // .startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                            var arrayClientsClaims = []    
                            data.docs.forEach(itemClaim => {
                                    var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                    // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                    }
                                })
                                this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(!this.clinicianName && this.clinicianStatus && !this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('approvalStatus', '==', this.claimStatus).where('statusClinician', '==', this.clinicianStatus).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && !this.clinicianRegion && !this.clientID && !this.clientName && this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('approvalStatus', '==', this.claimStatus).where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc')
                    // .startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            } else if(this.clinicianRegion) {
                // QUERY STATUS CLINICIAN
                if(!this.clinicianName && !this.clinicianStatus && !this.claimStatus && !this.clientID && !this.clientName && !this.clinicianBorough && !this.clinicianProgram) {
                    
                    // .collection("clientsClaims")
                    // .where("region", "==", "Central")
                    // var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('region', '==', this.clinicianRegion).limit(50)
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('region', '==', this.clinicianRegion).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(this.clinicianName && !this.clinicianStatus && !this.claimStatus && !this.clientID && !this.clientName && !this.clinicianBorough && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('region', '==', this.clinicianRegion).where('clinicianID', '==', this.clinicianName.codigo).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && !this.claimStatus && !this.clientID && this.clientName && !this.clinicianBorough && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('region', '==', this.clinicianRegion).where('client', '==', this.clientUID).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && this.claimStatus && !this.clientID && !this.clientName && !this.clinicianBorough && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('region', '==', this.clinicianRegion).where('approvalStatus', '==', this.claimStatus).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && !this.claimStatus && !this.clientID && !this.clientName && !this.clinicianBorough && this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('region', '==', this.clinicianRegion).where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)

                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && !this.claimStatus && !this.clientID && !this.clientName && this.clinicianBorough && !this.clinicianProgram) {
                    // .collection("clientsClaims")
                    // .where("region", "==", "Central")
                    // var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('region', '==', this.clinicianRegion).limit(50)
                    // console.log(this.clinicianBorough)
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('regionBorough', '==', this.clinicianBorough).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(this.clinicianName && !this.clinicianStatus && !this.claimStatus && !this.clientID && !this.clientName && this.clinicianBorough && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('regionBorough', '==', this.clinicianBorough).where('clinicianID', '==', this.clinicianName.codigo).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && !this.claimStatus && !this.clientID && this.clientName && this.clinicianBorough && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('regionBorough', '==', this.clinicianBorough).where('client', '==', this.clientUID).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && this.claimStatus && !this.clientID && !this.clientName && this.clinicianBorough && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('regionBorough', '==', this.clinicianBorough).where('approvalStatus', '==', this.claimStatus).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && this.claimStatus && !this.clientID && !this.clientName && this.clinicianBorough && this.clinicianProgram) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('regionBorough', '==', this.clinicianBorough).where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                        // }
                    // })
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            } else if(this.clientID) {
                // QUERY CLIENT ID
                if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientName && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('clientID', '==', this.clientID).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientName && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('clientID', '==', this.clientID).where('clinicianID', '==', this.clinicianName.codigo).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && this.clientName && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('clientID', '==', this.clientID).where('client', '==', this.clientUID).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientName && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('clientID', '==', this.clientID).where('approvalStatus', '==', this.claimStatus).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
        }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientName && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('clientID', '==', this.clientID).where('region', '==', this.clinicianRegion).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientName && this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('clientID', '==', this.clientID).where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                        var arrayClientsClaims = []    
                        data.docs.forEach(itemClaim => {
                                var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                                // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                                }
                            })
                            this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            } else if(this.clinicianProgram) {
                // QUERY CLIENT ID
                if(!this.clinicianName && !this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID) {
                    
                    var ref = db.collection('clientsClaims').where('approvalStatus', 'in', ['approved','paid']).where('status', '>=', 'active').where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc')
                    // <!-- .startAfter(this.latestDocQuery || 0).limit(50) -->
                    var data = await ref.get()

                    // ouputs docs
                    // .then(docsClients => {
                    var arrayClientsClaims = []
                    data.docs.forEach(itemClaim => {
                        var filterClaim = arrayClientsClaims.filter(ele => ele.id === itemClaim.id)
                        // if(itemClaim.data().clientName && itemClaim.data().clinicianName && filterClaim.length === 0) {
                        if(filterClaim.length === 0) {
                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                            if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                                            var didClientUtilizeSlidingScale = false
                                            var isInsuranceUtilizedForSession = false
                                            var didClientProvideCopay = false
                                            var ammountInsuranceCompanyPay = []
                                            var ifNotUtilizingInsuranceSession = []
                                            var copayAmount = []
                                            var amountSliding = []
                                            if(itemClaim.data().itemsFormRepeater.length >= 1) {
                                                var resultInsurance = itemClaim.data().itemsFormRepeater.filter(ele => ele.isInsuranceUtilizedForSession === 'Yes');
                                                var resultSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientUtilizeSlidingScale === 'Yes');
                                                var resultdidClientProvideCopay = itemClaim.data().itemsFormRepeater.filter(ele => ele.didClientProvideCopay === 'Yes');
                                                var resultifNotUtilizingInsuranceSession = itemClaim.data().itemsFormRepeater.filter(ele => ele.ifNotUtilizingInsuranceSession);
                                                var resultcopayAmount = itemClaim.data().itemsFormRepeater.filter(ele => ele.copayAmount);
                                                var resultamountSliding = itemClaim.data().itemsFormRepeater.filter(ele => ele.amount);
                                                var resultAmmountInsuranceCompanyPay = itemClaim.data().itemsFormRepeater.filter(ele => ele.enterAmmountInsuranceCompanyPay)

                                                if(resultInsurance.length >= 1) {
                                                    isInsuranceUtilizedForSession = true
                                                }

                                                if(resultSliding.length >= 1) {
                                                    didClientUtilizeSlidingScale = true
                                                }

                                                if(resultdidClientProvideCopay.length >= 1) {
                                                    didClientProvideCopay = true
                                                }

                                                if(resultAmmountInsuranceCompanyPay.length >= 1) {
                                                    ammountInsuranceCompanyPay = resultAmmountInsuranceCompanyPay
                                                }

                                                if(resultifNotUtilizingInsuranceSession.length >= 1) {
                                                    ifNotUtilizingInsuranceSession = resultifNotUtilizingInsuranceSession
                                                }

                                                if(resultcopayAmount.length >= 1) {
                                                    copayAmount = resultcopayAmount
                                                }

                                                if(resultamountSliding.length >= 1) {
                                                    amountSliding = resultamountSliding
                                                }
                                            }

                                            var validateFixed = itemClaim.data().amountBilledToCFE.includes('.') ? parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(itemClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                            var amountBilledToCFE = itemClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                            var obj = {
                                                id: itemClaim.id,
                                                tgs: itemClaim.data().noGroupSessions ? parseInt(itemClaim.data().noGroupSessions, 10) : '',
                                                tis: itemClaim.data().noIndividualSessions ? parseInt(itemClaim.data().noIndividualSessions, 10) : '',
                                                trs: itemClaim.data().noRemoteSessions ? parseInt(itemClaim.data().noRemoteSessions, 10) : '',
                                                tas: itemClaim.data().noAssessmentSessions ? parseInt(itemClaim.data().noAssessmentSessions, 10) : 0,
                                                tfs: itemClaim.data().noFamilySessions ? parseInt(itemClaim.data().noFamilySessions, 10) : 0,
                                                tcs: itemClaim.data().noCoupleSessions ? parseInt(itemClaim.data().noCoupleSessions, 10) : 0,
                                                client: itemClaim.data().client ? itemClaim.data().client : '',
                                                clinician: itemClaim.data().clinician ? itemClaim.data().clinician : '',
                                                region: itemClaim.data().regionClient ? itemClaim.data().regionClient : '',
                                                regionClinician: itemClaim.data().regionClinician ? itemClaim.data().regionClinician : itemClaim.data().region ? itemClaim.data().region : '',
                                                approvalStatus: itemClaim.data().approvalStatus ? itemClaim.data().approvalStatus : '',
                                                slidingScale: didClientUtilizeSlidingScale,
                                                insuranceSession: isInsuranceUtilizedForSession,
                                                didClientProvideCopay: didClientProvideCopay,
                                                inuis: ifNotUtilizingInsuranceSession,
                                                aicp: ammountInsuranceCompanyPay,
                                                amountBilledToCFE: amountBilledToCFE,
                                                copayAmount: copayAmount,
                                                amountSliding: amountSliding,

                                                iRIS: itemClaim.data().insuranceReimbursementRateForIndividualSessions ? itemClaim.data().insuranceReimbursementRateForIndividualSessions : 0,
                                                iRRGS: itemClaim.data().insuranceReimbursementRateForGroupSessions ? itemClaim.data().insuranceReimbursementRateForGroupSessions : 0,
                                                iRRRS: itemClaim.data().insuranceReimbursementRateForRemoteSessions ? itemClaim.data().insuranceReimbursementRateForRemoteSessions : 0,
                                                isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim ? itemClaim.data().isInsuranceUtilizedForClaim : '',
                                                clientProgressThisMonth: itemClaim.data().clientProgressThisMonth ? itemClaim.data().clientProgressThisMonth : '',
                                                cRSWTS: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions ? itemClaim.data().clientReportsSatisfactionWithTreatmentSessions : '',

                                                claimDate: itemClaim.data().claimDateMonth ? '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear : '',
                                                insurance: itemClaim.data().clientInsurance ? itemClaim.data().clientInsurance : '',
                                                clientID: itemClaim.data().clientID && itemClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? itemClaim.data().clientID : (itemClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? itemClaim.data().idOASASProgram : ''),
                                                language: itemClaim.data().languageClient ? itemClaim.data().languageClient : '',
                                                // gender: itemClaim.data().clientGender ? itemClaim.data().clientGender : '',
                                                clinicianProgram: itemClaim.data().clinicianProgram ? itemClaim.data().clinicianProgram : ''
                                            }

                                            arrayClientsClaims.push(obj)
                                        }
                        }
                    })
                    this.arrayFiltradoReportsFinanceInformationMethod(arrayClientsClaims)
                    // console.log(this.itemsClaimsQuery.length)
                    if(!data.empty) {
                        this.latestDocQuery = data.docs[data.docs.length - 1]
                    }
                    this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                    this.apiLoaded = false
                    this.loadingInfiniteScroll = false
                    this.isBusy = false
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            }

            // this.json_data = []
        },
        checkActiveFilters() {
            if(this.startDate || this.endDate || this.clinicianName || this.clientName || this.noIndividualSessions || this.noGroupSessions || this.noRemoteSessions || this.noAssessmentSessions || this.noCoupleSessions || this.noFamilySessions || this.isInsuranceUtilizedForClaim || this.clientProgressThisMonth || this.clientReportsSatisfactionWithTreatmentSessions || this.didClientUtilizeSlidingScale || this.region || this.insuranceRates || this.didClientProvideCopay || this.clinicianBorough || this.clinicianProgram) {
                // console.log('st')
                this.activeFilters = true
                if(this.arrayFiltradoReportsFinanceInformation.length === 0) {
                    this.queryClaims()
                }
            } else {
                // console.log('st2')
                // this.isBusy = false
                this.activeFilters = false

                // this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/finance-reports/counters')
                // .then(res => {
                //     // console.log(res.data.length)
                //     res.data.forEach(ele => {
                        // this.counterInsuranceUtilizedForSession = ele.counterInsuranceUtilizedForSession
                        // this.counterInsuranceReimbursementAmount = ele.counterInsuranceReimbursementAmount
                        // this.counterclientsThatProvidedCopay = ele.counterclientsThatProvidedCopay
                        // this.counterAverageofCopay = ele.counterAverageofCopay
                        // this.counterTotalSlidingScale = ele.counterTotalSlidingScale
                        // this.counterAmountofSlidingScale = ele.counterAmountofSlidingScale
                        // this.counterClientDoesntHaveInsurance = ele.counterClientDoesntHaveInsurance
                        // this.counterDeductibleNotMet = ele.counterDeductibleNotMet
                        // this.counterDontAcceptClientsInsurance = ele.counterDontAcceptClientsInsurance
                        // this.counterInsuranceDoesNotCoverTelehealthServices = ele.counterInsuranceDoesNotCoverTelehealthServices
                        // this.counterOther = ele.counterOther
                        // this.isBusy = false
                //     })
                // })

                this.counterInsuranceUtilizedForSession = this.counterInsuranceUtilizedForSessionAtom
                this.counterInsuranceReimbursementAmount = this.counterInsuranceReimbursementAmountAtom
                this.counterclientsThatProvidedCopay = this.counterclientsThatProvidedCopayAtom
                this.counterAverageofCopay = this.counterAverageofCopayAtom
                this.counterTotalSlidingScale = this.counterTotalSlidingScaleAtom
                this.counterAmountofSlidingScale = this.counterAmountofSlidingScaleAtom
                this.counterClientDoesntHaveInsurance = this.counterClientDoesntHaveInsuranceAtom
                this.counterDeductibleNotMet = this.counterDeductibleNotMetAtom
                this.counterDontAcceptClientsInsurance = this.counterDontAcceptClientsInsuranceAtom
                this.counterInsuranceDoesNotCoverTelehealthServices = this.counterInsuranceDoesNotCoverTelehealthServicesAtom
                this.counterOther = this.counterOtherAtom

                setTimeout(() => {
                    this.isBusy = false
                }, 2000)
            }
        },
        checkReadyToExport() {
            if(this.json_data.length === 0) {
                this.readyToExport = false
            } else {
                this.readyToExport = true
            }
        },
        prevRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).subtract(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
            }
        },
        nextRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).add(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
            }
        },
        functionDateRange(val) {
            if(val === 'month') {
                // console.log(this.startDate)
                if(this.startDate) {
                    // '24/03/2022'
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                    // console.log(formatDate1,expiredDate)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(this.startDate,this.endDate)
                }
            } else if(val === 'quarter') {
                if(this.startDate) {
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                }
            } else if(val === 'year') {
                if(this.startDate) {
                    var formatDateNew = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var getYear = moment(formatDateNew).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = '01/01/'+getYear
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var getYear = moment(Date.now()).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = moment('01/01/'+getYear).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,dateUpdateFormat)
                }
            } else if(val === 'x') {
                this.selectedRangeDate = ''
                this.startDate = null
                this.endDate = null
            }
            
        },
        filterRestard() {
            // console.log('st3')
            // this.isBusy = false
            // !this.firstLoadData ? this.isBusyClientsSeen = false : ''
            // this.loadTotalClients = true
            // db.collection('clients').where('primaryLanguage', 'not-in', ['English', 'english']).get()
            // .then(docsClients => {
            //     // console.log(docsClients.size)
            //     this.totalNonEnglishClients = 0
            //     this.clientSatisfaction = 0
            //     docsClients.forEach(itemClient => {
            //         if(itemClient.data().primaryLanguage) {
            //             var languageLowerCase = itemClient.data().primaryLanguage.toLowerCase()
            //             // Total Non-English Clients
            //             if(!languageLowerCase.includes("english")) {
            //                 this.totalNonEnglishClients += 1
            //             }
            //         } else {
            //             this.totalNonEnglishClients += 1
            //         }
            //     })
            //     this.totalNonEnglishClientsStatic = this.totalNonEnglishClients
            //     this.loadTotalClients = false
            // })
        }
    },
    created() {
        this.firstLoadDOM = true
        this.firstLoadData = false
        this.readyToExport = false
        this.counterCases = 0

        var user = auth.currentUser
        db.collection('usuarios').doc(user.uid).get()
        .then(doc => {
            this.isDataSpecialist = false
            if(doc.data().role === 'staff') {
                if(doc.data().permissions.includes('data specialist')) {
                    this.isDataSpecialist = true
                }
            } else if(doc.data().role === 'admin') {
                this.isDataSpecialist = true
            }
        })

        // 
        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/finance-information-reports')
        .then(res => {
            this.isBusy = false
            this.firstLoadDOM = true
            this.counterCases = res.data.length
            this.setSnapshotClientActivityReportsFinanceInformation(res.data)
        })
        .catch(error => {
            console.log(error)
        })

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/finance-reports/counters')
        .then(res => {
            // console.log(res.data)
            res.data.forEach(ele => {
                this.counterInsuranceUtilizedForSessionAtom = ele.counterInsuranceUtilizedForSession
                this.counterInsuranceReimbursementAmountAtom = ele.counterInsuranceReimbursementAmount
                this.counterclientsThatProvidedCopayAtom = ele.counterclientsThatProvidedCopay
                this.counterAverageofCopayAtom = ele.counterAverageofCopay
                this.counterTotalSlidingScaleAtom = ele.counterTotalSlidingScale
                this.counterAmountofSlidingScaleAtom = ele.counterAmountofSlidingScale
                this.counterClientDoesntHaveInsuranceAtom = ele.counterClientDoesntHaveInsurance
                this.counterDeductibleNotMetAtom = ele.counterDeductibleNotMet
                this.counterDontAcceptClientsInsuranceAtom = ele.counterDontAcceptClientsInsurance
                this.counterInsuranceDoesNotCoverTelehealthServicesAtom = ele.counterInsuranceDoesNotCoverTelehealthServices
                this.counterOtherAtom = ele.counterOther

                this.counterInsuranceUtilizedForSession = ele.counterInsuranceUtilizedForSession
                this.counterInsuranceReimbursementAmount = ele.counterInsuranceReimbursementAmount
                this.counterclientsThatProvidedCopay = ele.counterclientsThatProvidedCopay
                this.counterAverageofCopay = ele.counterAverageofCopay
                this.counterTotalSlidingScale = ele.counterTotalSlidingScale
                this.counterAmountofSlidingScale = ele.counterAmountofSlidingScale
                this.counterClientDoesntHaveInsurance = ele.counterClientDoesntHaveInsurance
                this.counterDeductibleNotMet = ele.counterDeductibleNotMet
                this.counterDontAcceptClientsInsurance = ele.counterDontAcceptClientsInsurance
                this.counterInsuranceDoesNotCoverTelehealthServices = ele.counterInsuranceDoesNotCoverTelehealthServices
                this.counterOther = ele.counterOther
                this.isBusy = false
            })
        })

        // db.collection('taxonomies').doc('client').get()
        // .then(docTaxonomia => {
        //     // profile fields
        //     this.optionsLanguage = docTaxonomia.data().primaryLanguage
        //     this.optionsInsurance = docTaxonomia.data().insuranceType
        // })

        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            // profile fields
            this.optionsRegion = docTaxonomia.data().region

            var arrayProgram = [{value: null, text: 'Any Program'}]
            docTaxonomia.data().program.forEach(item => {
                if(item !== 'OASAS') {
                    arrayProgram.push({value: item, text: item})
                }
            })
            this.optionsProgram = arrayProgram
        })

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/all-clinicians')
        .then(res => {
            this.optionsClinicianName = [{id: 0, uid: 0, value: 'All Clinicians', full: 'All Clinicians'}]
            res.data.forEach(ele => {
                this.optionsClinicianName.push(ele)
            })
        })

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/clients')
        .then(res => { 
            this.optionsClientName = [{id: 0, uid: 0, value: 'All Clients'}]
            res.data.forEach(ele => {
                this.optionsClientName.push(ele)
            })
        })

        // update data client claims
        // db.collection('clientsClaims').where('status', '==', 'active').where('clientStatus', '==', 'active').get()
        // .then(docsClaims => {
        //     console.log(docsClaims.size)
        //     if(docsClaims.size) {
        //         docsClaims.forEach(dataClaim => {
        //             // update data client of claim
        //             if(dataClaim.data().client) {
        //                 db.collection('clients').doc(dataClaim.data().client).get()
        //                 .then(docClient => {
        //                     if(docClient.exists) {
        //                         db.collection('clientsClaims').doc(dataClaim.id).update({
        //                             // clientID: docClient.data().id,
        //                             // clientName: docClient.data().firstName + ' ' + docClient.data().lastname,
        //                             // clientStatus: docClient.data().status,
        //                             // languageClient: docClient.data().primaryLanguage,
        //                             // regionClient: docClient.data().countryOfResidence,
        //                             // clientGender: docClient.data().gender ? docClient.data().gender : '',
        //                             clientInsurance: docClient.data().insuranceType ? docClient.data().insuranceType : ''
        //                         })
        //                         .then(() => console.log('update insurance'))
        //                     }
        //                 })
        //             }
        //         })
        //     }
        // })
    }
}
</script>

<style>
.mx-input {
    height: 37px !important;
}
.form-control {
    background: #fff !important;
    background-color: #fff !important;
}
.range-date {
    padding: 8px;
    margin-top: 27px;
}
.vs__dropdown-option {
  white-space: normal !important;
}
.vs__dropdown-toggle {
  max-height: 38px;
}
.vs__selected-options {
  overflow: auto;
}
.vs__selected-options {
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>